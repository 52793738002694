import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GlobalConstants } from './global-variables';
@Injectable()
export class ApiService {

	constructor(
		private http: HttpClient,
		public GlobalConstants: GlobalConstants
	) {
		if (this.GlobalConstants.data.api_version == 'v2') {
			environment.corePath = environment.corePathV2;
			environment.offerPath = environment.offerPathV2;
		}
	}
	reloadTemplate = new EventEmitter<any>();
	getUser(userId) {
		return this.http.get<any>(environment.corePath + '/users/' + userId, {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}
	getUserDetails() {
		return this.http.get<any>(environment.corePath + '/user', {})
			.pipe(
				map(res => res || res),
				catchError(error => throwError(error))
			);
	}

	getAllCountries() {
		return this.http.get(environment.corePath + "/countries").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getApplicableCountries() {
		return this.http.get(environment.offerPath + "/countries").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getAllInternationalCountries() {
		return this.http.get(environment.offerPath + "/international_offers/countries").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getAllCities(params) {
		return this.http.get(environment.corePath + "/cities", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getAllInternationalCities(params) {
		return this.http.get(environment.offerPath + "/international_offers/cities", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getAllLocalities(params) {
		return this.http.get(environment.corePath + "/localities", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCards(params = {}) {
		return this.http.get<any>(environment.corePath + '/user/user_cards', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
	}
	fetchCards(params = {}) {
		return this.http.get<any>(environment.corePath + '/user/user_cards/fetch', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
	}

	getCardsWithPoints(params = {}) {
		return this.http.get<any>(environment.corePath + '/user_card', {
			params: params
		})
			.pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
	}

	binValidation(bin) {
		return this.http.get<any>(environment.offerPath + "/bins/card_details?bin=" + bin).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}
	cardValidation(bin) {
		return this.http.post<any>(environment.corePath + "/bins/validate", {bin: bin}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		)
	}
	addCard(bin, bin_type ){
		return this.http.post<any>(environment.corePath + "/user/user_cards", {bin: bin, bin_type : bin_type }).pipe(
		  map(res => res || []),
		  catchError(error => throwError(error))
		);
	}
	validateCard(bin, bin_type) {
		return this.http.post<any>(environment.corePath + "/user/user_cards/search",{bin:bin, bin_type:bin_type}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getRedemptions(params = {}) {
		return this.http.get(environment.offerPath + "/redemptions?", { params: params }).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getClient(v2 = false) {
		var cpath = v2 == false ? environment.corePath : environment.corePathV2;
		return this.http.get(cpath + "/client").pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getOffers(params, isNearBy?, isInternational?) {
		if (isInternational) return this.getInternationalOffers(params);
		return this.http.get(environment.offerPath + "/offers?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCategories(params,parent_project = false) {
		let httpOptions = {
			params: params
		};
		if(parent_project){
			 httpOptions['headers'] =  new HttpHeaders({
					'exclude_project_id': 'true'
				})
		}
		return this.http.get(environment.offerPath + "/categories?elgble=true", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCategoryDetails(params = {}, parent_project = false) {
		let httpOptions = {
			params: params
		};
		if(parent_project){
			 httpOptions['headers'] =  new HttpHeaders({
					'exclude_project_id': 'true'
				})
		}
		return this.http.get(environment.offerPath + "/category_details?elgble=true", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getCategoryDetail(id, params) {
		return this.http.get(environment.offerPath + "/category_details/" + id + "?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getMerchants(params, parent_project = false) {
		let httpOptions = {
			params: params
		};
		if(parent_project){
			 httpOptions['headers'] =  new HttpHeaders({
					'exclude_project_id': 'true'
				})
		}
		return this.http.get(environment.offerPath + "/merchants?elgble=true", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	searchMerchants(params ,parent_project = false) {
		let httpOptions = {
			params: params
		};
		if(parent_project){
			 httpOptions['headers'] =  new HttpHeaders({
					'exclude_project_id': 'true'
				})
		}
		return this.http.get(environment.offerPath + "/merchants?elgble=true", httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getInternationalMerchants(params) {
		// params['per_page'] = 100;
		return this.http.get(environment.offerPath + "/international_offers/merchants?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getInternationalCategories(params?: any) {
		let data = params || {};
		// params['per_page'] = 30;
		return this.http.get(environment.offerPath + "/international_offers/categories?elgble=true", {
			params: data
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	getInternationalOffers(params) {
		// params['per_page'] = 30;
		return this.http.get(environment.offerPath + "/international_offers?elgble=true", {
			params: params
		}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	redeem(offer, params) {
		return this.http.post(environment.offerPath + "/offers/" + offer.id + "/redeem?elgble=true", params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}

	redeemByBin(offer, bin) {
		return this.http.post(environment.offerPath + "/offers/" + offer.id + "/redeem?elgble=true", { bin: bin }).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	createTokenUser(token, uid?, client?,attr = 'token') {
		let params = {};
		if (token) params[attr] = token;
		if (uid) params['uid'] = uid;
		if (client) params['client'] = client;
		if (this.GlobalConstants.data.api_version == 'v1') {
			return this.http.post(environment.corePath + "/sessions/create_with_user", params).pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
		}
		else {
			return this.http.post(environment.corePath + "/sessions", params).pipe(
				map(res => res || []),
				catchError(error => throwError(error))
			);
		}
	}

	checkCardEligibility(bin, bin_type, bundle_id) {
		return this.http.get(environment.offerPath + "/bins/validate?bin=" + bin + "&bundle_id=" + bundle_id).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}


	getBanners(params) {
		return this.http.get(environment.offerPathV2 + "/client_banners", params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	getTerms(id) {
		return this.http.get(environment.offerPathV2 + "/offers/" + id + '/terms').pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}




	checkPinCode(pin) {
		return this.http.get(`https://api.postalpincode.in/pincode/${pin}`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
  }
	checkCordinates(cordinates) {
		return this.http.get(`https://api.opencagedata.com/geocode/v1/json?q=${cordinates}&key=d73135d9826a49ef91a2bf334da1be37`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
  }
	getProjects(id) {
		const httpOptions = {
      headers: new HttpHeaders({
        'exclude_project_id': 'true'
      })
    }
		return this.http.get(`${environment.offerPathV2}/projects/${id}?elgble=true`,httpOptions).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
  }
	getPaymentLink(params){
		return this.http.post<any>(environment.apiUrlPaV1 +'payments', params).pipe(
		  map(res => res || []),
		  catchError(error => throwError(error))
		);
	}
	updateUserCreds(params){
		return this.http.put(environment.corePathV2 + "/user",params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	confirmEmail(params){
		return this.http.put(environment.corePathV2 + "/user/verify_email",params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	resendEmail(params = {}){
		return this.http.post<any>(environment.corePathV2 +'/user/resend_email_verification', params).pipe(
		  map(res => res || []),
		  catchError(error => throwError(error))
		);
	}
	getIpStackLocation() {
		return this.http.get(`https://api.ipstack.com/check?access_key=${environment.ipstackKey}&format=1`).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	addLocation(params) {
		return this.http.post<any>(environment.corePath + "/user/user_locations/log", params).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
	resendVoucher(id){
		return this.http.get(environment.offerPath + "/offers/" + id + "/resend_voucher", {}).pipe(
			map(res => res || []),
			catchError(error => throwError(error))
		);
	}
}
