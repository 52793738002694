import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error500Component } from './pages/error500/error500.component';
import { Error404Component } from './pages/error404/error404.component';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule)
  // },
  // {
  //   path: 'offers/:category',
  //   loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule)
  // },
  // {
  //   path: 'offers/:category/:sub_category',
  //   loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule)
  // },
  {
    path: 'offer/:code',
    loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule)
  },
  // {
  //   path: '', component: Error404Component
  // },
  // {
  //   path: 'offers/:category',
  //   loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule)
  // },
  // {
  //   path: 'offers/:category/:sub_category',
  //   loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule)
  // },
  // {
  //   path: 'offer/:code',
  //   loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule)
  // },
  {
    path: 'offer',
    loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule)
  },
  {
    path: ':code/offers',
    loadChildren: () => import("./pages/homepage/homepage.module").then(m => m.HomepageModule)
  },
  {
    path: ':code/offers/:code',
    loadChildren: () => import("./pages/offers/offers.module").then(m => m.OffersModule)
  },
  {
    path: ':code/redemption-history',
    loadChildren: () => import("./pages/redemption/redemption.module").then(m => m.RedemptionModule)
  },
  {
    path: 'redemption-history',
    loadChildren: () => import("./pages/redemption/redemption.module").then(m => m.RedemptionModule)
  },
  { path: "error", component: Error500Component },
  {
    path: "info",
    loadChildren: () =>
      import("./info/info.module").then(m => m.InfoModule)
  },
  {
    path: 'confirm-email/:code',
    loadChildren: () => import("./pages/email-confirmation/email-confirmation.module").then(m => m.EmailConfirmationModule)
  },
  { path: "**", pathMatch: "full", component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
