import { Injectable, EventEmitter } from '@angular/core';
// import { HttpClient } from './http-client';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AESEncryptDecryptService } from 'src/app/authentication/encryption/AESEncryptDecryptService.service';
import { User } from './user.modal';



@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public errorEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public showVerifyEmailEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public reloadInit: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private _AESEncryptDecryptService: AESEncryptDecryptService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

      environment.corePath = environment.corePathV2;
      environment.offerPath = environment.offerPathV2;

  }

  public get currentUserValue(): any {
    return this.http.get<any>(environment.corePath + '/user')
    map(res => {
      return res;
    }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err);
      })
  }

  getCurrentUser(bin, bin_type) {
    return this.http.get<any>(environment.corePath + '/user').pipe(
      map(res => res || []),
      catchError(error => throwError(error))
    );
  }


  validateExistingUser(mobile: string, calling_code: string, country_id) {
      return this.http.post<any>(environment.corePath + '/users/verify', {mobile: mobile, calling_code: calling_code, country_id:country_id})
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );
  }


  createUser(mobile: string, email: string, calling_code: string) {

      return this.http.post<any>(environment.corePath + '/users', { mobile: mobile, email: email, calling_code: calling_code })
        .pipe(
          map(res => {
            return res;
          }),
          catchError((err: HttpErrorResponse) => {
            return throwError(err);
          })
        );



  }

  requestUserOtp(mobile: string, userId: string, otp_type, calling_code: string) {
    return this.http.post<any>(environment.corePath + '/users/' + userId + '/otps', { mobile: mobile, calling_code: calling_code })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  validateUserOtp(userId: string, otp: string, otpType: string, mobileNum?: any) {
    return this.http.post<any>(environment.corePath + '/users/' + userId + '/otps/verify', { code: otp })
      .pipe(
        map(res => {
          var user = {
            id: userId,
            token: res.token
          }
          var user2 = {
            id: userId,

          }
          if (user && user.token) {
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(res);
          }

          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('card_verified');
    localStorage.removeItem('cardSelected');
    localStorage.removeItem('eligible_banners');
    localStorage.removeItem('locationData');
    localStorage.removeItem('userCountry');
    localStorage.removeItem('currentUserCard');
    sessionStorage.removeItem('client');
    localStorage.removeItem('userCard');
    localStorage.removeItem('selectedCardType');
    const firstLoad = localStorage.getItem('firstLoad');
    const userCountry = localStorage.getItem('userCountry');
    const redirectData = localStorage.getItem('redirectData');

    localStorage.clear();
    sessionStorage.clear();
    if(userCountry){
      localStorage.setItem("userCountry", userCountry);
    }
    if (firstLoad) {
      localStorage.setItem("firstLoad", firstLoad);
    }
    if (redirectData) {
      localStorage.setItem("redirectData", redirectData);
    }



    this.currentUserSubject.next(null);
  }

  deleteToken() {
    if (JSON.parse(localStorage.getItem('currentUser'))) {
      var currentUserId = JSON.parse(localStorage.getItem('currentUser')).id
    }
    return this.http.post<any>(environment.corePath + '/sessions/' + currentUserId, {})
      .pipe(
        map(res => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err);
        })
      );
  }
}
