import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { DOCUMENT } from '@angular/common';
import { GlobalConstants } from 'src/app/pages/common/global-variables';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) private document: any, private authenticationService: AuthenticationService,
    public GlobalConstants: GlobalConstants) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        event => { },
        error => {
          if (error.status == 401) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('card_verified');
            localStorage.removeItem('cardSelected');
            localStorage.removeItem('eligible_banners');
            localStorage.removeItem('locationData');
            localStorage.removeItem('currentUserCard');
            localStorage.removeItem('userCard');
            localStorage.removeItem('userCards');
            localStorage.removeItem('expire_at');
            const firstLoad = localStorage.getItem('firstLoad');
            const userCountry = localStorage.getItem('userCountry');
            const redirectData = localStorage.getItem('redirectData');
            sessionStorage.clear();
            localStorage.clear();
            if(userCountry){
              localStorage.setItem("userCountry", userCountry);
            }
            this.authenticationService.errorEvent.emit(true);
            if(firstLoad){
              this.authenticationService.reloadInit.emit(true);
              localStorage.removeItem('firstLoad');
            }
            if (redirectData) {
              localStorage.setItem("redirectData", redirectData);
            }
          }
          if (error instanceof HttpErrorResponse) {
            return throwError(error);
          }
        }
      )
    );
  }
}
