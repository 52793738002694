export const environment = {
  production: true,
  corePath: "https://api-customer.poshvine.com/core_service/v1",
  corePathV2: "https://api-customer.poshvine.com/cs/v1",
  offerPath: "https://api-customer.poshvine.com/offer_service/v1",
  giftPathV2: "https://offers-api.poshvine.com/offer_service/v2",
  offerPathV2: "https://api-customer.poshvine.com/ps/v1",
  apiUrlBsV1: "https://api-customer.poshvine.com/bs/v1/",
  apiUrlPaV1: "https://api-customer.poshvine.com/pa/v1/",
  apiUrlLsV1: "https://api-customer.poshvine.com/ls/v1/",
  baseUrl: "https://api-customer.poshvine.com/",
  analytics: "https://assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-5e1bff55b076.min.js",
  gmApiKey: 'AIzaSyAXREnbpzSGulf8xS5J43tsYwrbBFltR7k',//'AIzaSyC2AtPyxZua0AcvWyoPZi-M_WuXoOo_gRc'
  ipstackKey: '022375a36968cebce87e10e1c6447ef6',
  sentryDsn: 'https://196c5cd8d679750ab30d3efee003f769@o515678.ingest.us.sentry.io/4507446620913664',
}
