import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class GlobalConstants {
    client: any;
    data: any;
    sub_projects_to_skip_location: Array<string> = ['visa-times-prime'];

    constructor(@Inject(DOCUMENT) private document: any, private router: Router) {

            this.client = "visaoffers";
                this.data = {
                  "logo": {
                      "url": "https://images.poshvine.com/logos/visa-logo.png",
                      "footerurl": "https://images.poshvine.com/logos/visa-logo.png",
                      "desktop_url": "https://images.poshvine.com/logos/visa-logo.png",
                      "modal_url": "https://images.poshvine.com/visa_PNG4.png",
                      "favicon": "./assets/favicon/visa.ico",
                      "head_logo_height": "28",
                      "coupon_modal_logo": "https://images.poshvine.com/logos/visa-logo.png"
                  },
                    "assets": {
                        "img": {},
                        "manifest": "manifest.webmanifest"
                    },
                    "links": {
                        "projectLink": "https://visaoffers.poshvine.com/"
                    },
                    "colors": {
                        "header_bg_color": "#1a1f71",
                        "coupon_modal_bg_color": "#1a1f71",
                        "offer_title_color": '#1a1f71',
                        "main_text_color": '#262626',
                        "medium_text_color": '#5C5C5C',
                        "light_text_color": '#9D9D9D',
                        "main_bg_color": '#F4F4F4',
                        "active_card_bg_color": '#F4F4F4',
                        "seconday_bg_color": '#FDFCFA',
                        "mainBtn_color": '#1a1f71',
                        "inactive_btn_color": '#D8D8D8',
                        "btn_positive_action_color": '#06B08A',
                        "active_text_color": '#1a1f71',
                    },
                    "legal_name" : "Visa Offers",
                    "font_family": {
                      "link": "https://images.poshvine.com/fonts/niyo-font/MuseoSans.css",
                      "name": "MuseoSans"
                    },
                    "x_header": {
                        "prod": {
                            'client_id': '3825376c-4e14-4855-9ba7-ee89b1b91af4',
                            'main_project': 'ae103ba6-6dda-4d1a-aa8b-6096c40ffba2'
                        },
                        "sandbox": {
                            'client_id': 'eb1c2849-8784-4382-896e-cd66290935ea',
                            'main_project': '0049ca94-26a5-4f14-b65a-b36e3b1882ee'
                        },
                    },
                    "search_type": 2,
                    "desktop_category_count": 7,
                    "carouselInterval": 4000,
                    "card_num_type": "first8",
                    "card_type": "Debit",
                    "tabs_type": "category",
                    "tabs_type_mobile": "category",
                    "has_exclusive": false,
                    "banner_type": "none",
                    "login_button_needed": true,
                    "loginAt": "otp",
                    "login_theme": 1,
                    "login_steps": ['card','mobile','otp'],
                    "auto_expiry": {
                        "time": "30",
                        "enabled": false
                    },
                    "template": 4,
                    "header_type": "idfc",
                    "show_saving_for_bundle_codes": {},
                    "download_app_link": {
                        'header': false,
                        'footer': false,
                        'android_link': null,
                        'apple_link': null
                    },
                    "login_modal_type": "desktop-prev-header",
                    "supported_devices": ['mobile'],
                    "benefits": ['giftcards'],
                    "api_version": 'v2',
                    "token_attr": 'token',
                    "personalized_config": {
                        "switch_card": false,
                        "autocard_check": false,
                        "view_savings": true,
                        "external_otp": false,
                        "redemption_page_needed": true,
                        "saving_page": false,
                        "resend_allowed": true,
                        "add_new_card": false,
                        "footer_logo": true,
                        "force_auth": false,
                        "token_auth": false,
                        "desktop_search": true,
                        "mobile_search": true,
                        "auth": true,
                        "coupon_modal": true,
                        "coupon_show": true,
                        "amount_show": false,
                        "modal_redeem_button": true,
                        "mobile_home_back_button": true,
                        "show_card_used": false,
                        "allow_user_create": true,
                        "dedicated_banner": false,
                        "mobile_banner": false,
                        "authenticate_before_claim": true,
                        "display_bundle_category_title": true,
                        "disclaimer_popup": false,
                        "cancelable_auth": true,
                        "mobile_login": false,
                        "is_concierge": false,
                        "has_card": true,
                        "home_subcategory_dropdown": true,
                        "home_subcategory_bar": false,
                        "has_near_by": false,
                        "has_international": false,
                        "mobile_profile_dropdown": false,
                        "category_page": true,
                        "authenticate_bin": true,
                        "datalayer": false,
                        "has_add_card_text": false,
                        "single_step_login": false,
                        "skip_card_if_exist": false,
                        "custom_gtm_tags": false,
                        "show_terms_before_redeem": true,
                        "details_desktop_tabs": false,
                        "show_special_category": false,
                        "send_project_id": true,
                        "show_card_applicability": false,
                        "card_applicability_fetch": 'local',
                        "show_card_select_on_auth": false,
                        "special_bundle": true,
                        "external_login_banner": false,
                        "send_as_gift": false,
                        "applicableTag": true,
                        "has_maps": true
                    },
                    "titles": {
                        "homepage_pagetitle": "Offers & Privileges - Visa",
                        "homepage_description": "",
                        "homepage_keywords": "",
                        "header_title": "Offers & Perks",
                        "modal_mobile_label": "Enter your mobile number to proceed",
                        "card_name" : "Visa Card",
                        "platform_title": "Visa Offers",
                        "concierge_name": "Visa Offers",
                        "login_modal_control": "Opt in to receive offers & benefits for"
                    },
                    "footer_additional_content": "<p>Disclaimer: IDFC FIRST Bank Offers & Privileges is a platform for communication of personalized offers extended by Merchants to IDFC FIRST Bank's Customers. IDFC FIRST Bank is only communicating the offers extended by Merchants to its Customers and not Selling/Rendering any of these Products/Services. IDFC FIRST Bank is neither guaranteeing nor making any representation. IDFC FIRST Bank is not responsible for Sale/Quality/Features of the Products/Services under the offers.</p><p>Site is best viewed in Chrome V23+, Firefox V15+, Safari 6+ and IE11+ at 1024 x 768 pixels resolution, or above.</p>",
                    "content": {
                        "bin_error": {
                            "first4_last4": "Dear customer, please re-check the first 4 and last 4 card number you have entered",
                            "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered"
                        },
                        "bin_invalid": {
                            "first4_last4": "Please enter your valid first 4 and last 4 digits of your debit card correctly. In case the issue persists, write to support-poshvine@razorpay.com",
                            "first6": "Dear customer, please re-check the first 6 digits of your card number you have entered"
                        },
                        "preset_error": "Invalid Credentials!"
                    },
                    "email": {
                        display: 'support-poshvine@razorpay.com', val: 'support-poshvine@razorpay.com'
                    }
                    // "phone": {
                    //     display: '1860-5001-111', val: '18605001111'
                    // }
                };



    }
}
