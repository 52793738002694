import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NoDataComponent } from './components/no-data/no-data.component';
import { FooterComponent } from './components/footer/footer.component';
import { CouponModalComponent } from './components/coupon-modal/coupon-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';

import { OffersCardComponent } from './components/offers-card/offers-card.component';
import { RouterModule } from '@angular/router';

import { LazyImgDirective } from './directives/lazyload.directive';
import { AuthenticationModule } from '../authentication/authentication.module';
import { HeaderComponent } from './components/header/header.component';
import { SearchComponent } from './components/header/search-modal/search-modal.component';
import { SearchBasicComponent } from './components/header/search-basic/search-basic.component';
import { CardSwitchComponent } from './components/card-switch/card-switch.component';
import { CountryModalComponent } from './components/country-modal/country-modal.component';
import { LoaderComponent } from './components/loader/loader.component';
import { CardLoaderComponent } from './components/loader/card-loader/card-loader.component';
import { NotEligibleModalComponent } from './components/not-eligible-modal/not-eligible-modal.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PixelModule } from 'ngx-pixel';
import { ModuleWithProviders } from '@angular/core';
import { PennyDropModalComponent } from './components/penny-drop-modal/penny-drop-modal.component';

declare var Razorpay: any;
@NgModule({
    declarations: [
        // Components
        NoDataComponent,
        FooterComponent,
        CouponModalComponent,
        OffersCardComponent,
        HeaderComponent,
        SearchComponent,
        SearchBasicComponent,
        CardSwitchComponent,
        CountryModalComponent,
        LoaderComponent,
        CardLoaderComponent,
        NotEligibleModalComponent,
        // Directives
        ImagePreloadDirective,
        LazyImgDirective,
        PennyDropModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
            closeButton: true,
            preventDuplicates: true,
            // disableTimeOut: true
        }),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        ModalModule.forRoot(),
        RouterModule,
        AuthenticationModule,
        PixelModule.forRoot({ enabled: false, pixelId: '1565150523616028' })
    ],
    exports: [
        // Modules
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        ModalModule,
        RouterModule,
        // Components
        NoDataComponent,
        FooterComponent,
        CouponModalComponent,
        OffersCardComponent,
        HeaderComponent,
        SearchComponent,
        SearchBasicComponent,
        CardSwitchComponent,
        CountryModalComponent,
        LoaderComponent,
        CardLoaderComponent,
        NotEligibleModalComponent,
        // Directives
        ImagePreloadDirective,
        LazyImgDirective,
        // Pipes,
        PennyDropModalComponent,
    ],
    providers: []
})
export class SharedModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: 'env', // you can also use InjectionToken
          useValue: environment
        },
        {
          provide: 'Razorpay',
          useValue: Razorpay
        }
      ]
    };
  }
}