import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from './authentication.service';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/pages/common/global-variables';
import { AESEncryptDecryptService } from 'src/app/authentication/encryption/AESEncryptDecryptService.service';

const loginApis = [
  'cs/v1/bins/validate'
]
const apiListToOmitProjectId = [];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: any,
    public GlobalConstants: GlobalConstants,
    private _AESEncryptDecryptService: AESEncryptDecryptService
  ) { }
  clientId: any;
  projectId: any;
  environment_type: any;
  dynamicProjectId: any;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes("restcountries") || request.url.includes("ipify.org") || request.url.includes("postalpincode") || request.url.includes("opencagedata") || request.url.includes("ipstack")) {
      return next.handle(request);
    }

    this.dynamicProjectId = false;
    this.environment_type = this.document.location.hostname.includes("sandbox") ? 'sandbox' : 'prod';
    this.clientId = this.GlobalConstants.data.x_header[this.environment_type].client_id;
    let pathname = this.document.location.pathname.split('/',3);
    if(request.headers.get('exclude_project_id')){
      this.projectId = this.GlobalConstants.data.x_header[this.environment_type].main_project;
    }
    else if(pathname.length == 1){
      this.dynamicProjectId = false;
    }
    else if(pathname.length == 3 && (pathname[2] == 'offers' || pathname[2] == 'redemption-history')){
      this.dynamicProjectId = true;
    }
    else if(pathname.length > 1 && pathname[1] == 'offers'){
      this.dynamicProjectId = false;
    }
    if(loginApis.some(v => request.url.includes(v)) && (localStorage.getItem('redirectData') || localStorage.getItem('headerLogin'))){
      var redirectData = JSON.parse(localStorage.getItem('redirectData'));
      var headerLogin = localStorage.getItem('headerLogin');
      if(headerLogin){
        if (localStorage.getItem('projectId') && this.dynamicProjectId) {
          this.projectId = localStorage.getItem('projectId');
        } else {
          this.projectId = this.GlobalConstants.data.x_header[this.environment_type].main_project;
        }
      }
      else if(redirectData){
        this.projectId = redirectData.projectId
      }
    }
    else if (localStorage.getItem('projectId') && this.dynamicProjectId) {
      this.projectId = localStorage.getItem('projectId');
    }
    else{
      this.projectId = this.GlobalConstants.data.x_header[this.environment_type].main_project;
    }

    request = request.clone({
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '-1',
        'X-Download-Options': 'noopen',
        'X-Client-Id': this.clientId
        // 'X-Project-Id': this.projectId
      }
    });
    if(!apiListToOmitProjectId.some(v => request.url.includes(v))){
      request = request.clone({
        setHeaders: {
          'X-Project-Id': this.projectId
        }
      });
    }
    request = request.clone({ withCredentials: true });
    if (localStorage.getItem('userCountry')) {
      let userCountry = JSON.parse(localStorage.getItem('userCountry'));
      if(userCountry.id){
        request = request.clone({
          setHeaders: {
            'X-Country-Id': userCountry.id
          }
        });
      }
    } else {
      request = request.clone({
        setHeaders: {
          'X-Country-Id': '625a3e8e-e493-421a-8ece-609f10b37442'
        }
      });
    }

    var currentUser;
    if (localStorage.getItem('currentUser')) {
      currentUser = JSON.parse(localStorage.getItem('currentUser'));
      // if (currentUser && currentUser.token && (request.headers.get('exclude_token_add') !== 'true')) {
      if (currentUser && currentUser.token) {
        request = request.clone({
          setHeaders: {
            'Authorization': 'Bearer ' + this._AESEncryptDecryptService.decrypt(currentUser.token)
          }
        });
      }
    }

    return next.handle(request)
      .pipe(
        tap(
          (response: HttpEvent<any>) => {
            return response
          },
          (error: HttpErrorResponse) => {
            return error
          },
          () => {
          }
        )
      )
  }
}
