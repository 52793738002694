import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthenticationComponent} from "./authentication.component";
import { ModalModule } from 'ngx-bootstrap/modal';
import {AddCardModalComponent} from "./addCard-modal/addCard-modal.component";
import {ConfirmEmaiModalComponent} from "./confirm-email-modal/confirm-email-modal.component";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    declarations: [AuthenticationComponent, AddCardModalComponent,ConfirmEmaiModalComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
    ],
    exports: [AuthenticationComponent, AddCardModalComponent,ConfirmEmaiModalComponent]
})
export class AuthenticationModule {
}
