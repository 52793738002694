import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router,
    private update: SwUpdate
  ) {
    this.deregisterServiceWorker();
    let allCountries = JSON.parse(localStorage.getItem("allCountries"));
    let userCountry = JSON.parse(localStorage.getItem("userCountry"));
    if((userCountry && userCountry.attributes && userCountry.attributes.flag_url && userCountry.attributes.flag_url.includes('restcountries')) || (allCountries && allCountries.length > 0 && allCountries[0] && allCountries[0].attributes && allCountries[0].attributes.flag_url && allCountries[0].attributes.flag_url.includes('restcountries'))){
      localStorage.removeItem('allCountries');
      localStorage.removeItem('userCountry');
      console.log('-')
    }
    this.updateClient();
  }

  ngOnInit() {
  }
  updateClient() {
    if (!this.update.isEnabled) {
      console.log('Not Enabled');
      return;
    }
    this.update.available.subscribe((event) => {
       this.update.activateUpdate().then(() => location.reload());
    });

    this.update.activated.subscribe((event) => {
      console.log(`current`, event.previous, `available `, event.current);
    });
  }
  private deregisterServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          console.log('registrations to unregister', registrations)
          for (const registration of registrations) {
            registration.unregister()
          }
        })
        .catch(function (err) {
          console.log('Service Worker registration failed: ', err)
        })
    }
  }
}
