<footer class="fluid-container bg-white">
    <div class="row footer-post-login" >
        <div class="col px-0">
			<div class="footer-container">
				<div class="links">
                    © {{currentYear()}} |
                    <a href="/info/privacy-policy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> |
                    <a href="/info/terms-and-conditions" target="_blank" rel="noreferrer noopener">Terms & Conditions</a>
				</div>
				<div class="best-viewed hide-on-mobile f300">
					Site best viewed in browsers Mozilla 3.5+, Chrome 3.0+, Safari 5.0+ on all desktops, laptops,
					and Android &amp; iOS mobile/tablet devices.
				</div>
	            <div class="powered-by flex align-items-center justify-content-center">
                        <span class="fnt-10 mar-right-5 mar-top-3"  style="color: #B5B8B7;">POWERED BY</span>
                        <a href="https://poshvine.com/" target="_blank" rel="noreferrer noopener"><img src="assets/poshvine.png" alt=""  style="height: 14px;"></a>
                        <div class="separator mar-top-3"></div>
                        <img src="assets/pci-dss-compliant.png" alt="" class="mar-top-2"  style="height: 14px;">
                </div>

			</div>
		</div>
    </div>
</footer>
